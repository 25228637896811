<template>
  <div>
    <!-- FAVORITE PROPERTIES -->
    <div
      class="max-w-6xl mx-auto container mx-auto bg-white mt-8 md:mt-18 mb-20"
    >
      <div class="container mx-auto">
        <section class="flex md:flex-row flex-wrap py-12">
          <div class="w-full md:w-1/2 flex items-center lg:pr-20 sm:pr-4">
            <input
              v-model="searchString"
              class="
                w-full
                text-gray-900
                border-gray-400
                mr-3
                border-b border-t-0 border-l-0 border-r-0
                px-5
                py-2
                text-xs
                focus:outline-none
              "
              @keydown="handleSearchKeyPress"
              @keyup="handleSearchKeyUp"
              placeholder="SEARCH OVER 100 PROPERTIES"
            />
            <Spinner :loading="loading" />
          </div>

          <div
            class="
              w-full
              md:w-1/2
              sm:mt-10
              md:mt-0
              lg:mt-0
              mt-10
              text-right
              px-2
            "
          >
            <span
              to=""
              class="
                inline-block
                text-xs
                border border-gray-400
                mr-2
                hover:bg-gray-100
                cursor-pointer
                relative
              "
              @click="limitDropdownShown = !limitDropdownShown"
            >
              <div
                v-show="limitDropdownShown"
                class="fixed top-0 right-0 bottom-0 left-0 z-40"
              ></div>
              <span class="inline-block px-4 md:px-8 py-3 p-2"
                >SHOW: {{ perPage }}</span
              >
              <ul
                v-show="limitDropdownShown"
                class="w-full bg-white z-50 rounded absolute border"
              >
                <li
                  @click="setLimit(5)"
                  class="text-left px-3 py-2 hover:bg-gray-100"
                >
                  5
                </li>
                <li
                  @click="setLimit(10)"
                  class="text-left px-3 py-2 hover:bg-gray-100"
                >
                  10
                </li>
                <li
                  @click="setLimit(15)"
                  class="text-left px-3 py-2 hover:bg-gray-100"
                >
                  15
                </li>
                <li
                  @click="setLimit(20)"
                  class="text-left px-3 py-2 hover:bg-gray-100"
                >
                  20
                </li>
              </ul>
            </span>
          </div>
        </section>
      </div>

      <SpecialOfferCard
        v-for="(offer, i) in offers"
        :key="i"
        :specialOffer="offer"
      ></SpecialOfferCard>

      <!-- <div class="container mx-auto px-4">
        <section class=" border">
          <div class="flex">
            <div class="w-1/2">
              <img class="" src="https://source.unsplash.com/xPsFXsbXJRg" />
            </div>

            <div class="w-1/2 text-left p-10">
              <h1 class="text-2xl">ADAARAN CLUB RANNALHI</h1>

              <div class="flex">
                <div class="w-1/6 text-left">
                  <p class="text-xs text-gray-500 mt-6 ">
                    STAR RATING
                  </p>
                </div>
                <div class="w-1/2 text-left">
                  <p class="text-xs text-gray-500 mt-6 ">
                    <span class="text-black pl-3"> 3 STAR BUDGET</span>
                  </p>
                </div>
              </div>

              <div class="flex">
                <div class="w-1/6 text-left">
                  <p class="text-xs text-gray-500 mt-3 ">
                    TRANSFER
                  </p>
                </div>
                <div class="w-1/2 text-left">
                  <p class="text-xs text-gray-500 mt-3 ">
                    <span class="text-black pl-3"> 25 MINUTE SPEEDBOAT</span>
                  </p>
                </div>
              </div>

              <p class="mt-8 text-xs">
                Escape to beachside hideaway surrounded by an abundance of
                indigenous, tropical flora. A typical morning greets you with
                views of waves. Enchanting sun and winds.
              </p>
              <p class="mt-6 ml-2 text-xs">
                Light flooded open-plan living area with retro fitting,
                fireplace, tv and seaplace lions.
              </p>
            </div>
          </div>

          <div class="w-full  text-right text-xs">
            <router-link to="" class="bg-gray-800 p-2 px-8 py-4 text-white"
              >VIEW RESORT</router-link
            >
          </div>
        </section>
      </div> -->

      <div class="flex justify-center mx-auto px-4 m-10">
        <!-- PAGINATION-->
        <Pagination
          v-if="paginationData"
          :paginationData="paginationData"
          url="/properties/page/"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SpecialOfferCard from "@/components/layout/SpecialOfferCard";
import Pagination from "@/components/base/Pagination";

export default {
  name: "SpecialOffers",
  data() {
    return {
      currentPage: this.$route.params.pageid || 1,
      perPage: this.$route.query.limit || 15,
      searchString: "",
      searchTypingTimeout: 500,
      loading: false,
      paginationData: null,
      // dropdowns
      limitDropdownShown: false,
      offers: [
        // {
        //   name: "Test Property",
        //   starRating: "5 Star Luxury",
        //   transfer: "20 Minute Speedboat",
        // },
        // {
        //   name: "Test Property Two",
        //   starRating: "4 Star Something",
        //   transfer: "20 Minute Seaplane",
        // },
        // {
        //   name: "Test Property",
        //   starRating: "5 Star Luxury",
        //   transfer: "20 Minute Speedboat",
        // },
        // {
        //   name: "Test Property Two",
        //   starRating: "4 Star Something",
        //   transfer: "20 Minute Seaplane",
        // },
      ],
    };
  },
  methods: {
    setLimit(limit) {
      this.perPage = limit;
      if (this.$route.params.pageid == 1) {
        this.getOffers();
      } else {
        this.$router.push(`/special-offers/page/1?type=${this.propertyType}`);
      }
    },
    async getOffers() {
      try {
        let uri = `${this.$api_v1}/packages?page=${this.currentPage}&limit=${this.perPage}`;

        if (this.searchString) {
          uri = `${uri}&search=${this.searchString}`;
        }

        if (this.starRating) {
          uri = `${uri}&starRating=${this.starRating._id}`;
        }

        if (this.transferType) {
          uri = `${uri}&transferType=${this.transferType._id}`;
        }

        const res = await this.$axios.get(uri);

        if (res.status === 200) {
          this.offers = res.data.packages;
          // console.log(res.data);
          this.paginationData = res.data.paginationData;
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$errorHandler(e);
      }
    },
    handleSearchKeyPress() {
      window.clearTimeout(window.searchTimer);
      this.loading = true;
    },
    handleSearchKeyUp() {
      window.clearTimeout(window.searchTimer);
      window.searchTimer = window.setTimeout(() => {
        if (this.$route.params.pageid == 1) {
          this.getOffers();
        } else {
          this.$router.push(`/properties/page/1?type=${this.propertyType}`);
        }

        // console.log("test");
      }, this.searchTypingTimeout);
      // console.log(this.timer);
    },
  },
  watch: {
    async $route(to, from) {
      from;
      if (to.name == "Offers") {
        await this.getOffers();
      }
      if (to.name == "OffersP") {
        this.currentPage = this.$route.params.pageid;

        await this.getOffers();
      }
    },
  },
  mounted() {
    this.getOffers();
  },
  components: {
    SpecialOfferCard,
    Pagination,
  },
};
</script>

<style scoped>
</style>