<template>
  <div class="property-card flex md:flex-row flex-wrap border m-4 md:m-0">
    <div
      :style="{
        backgroundImage: `url(${$api}/uploads/${specialOffer.featuredImage})`,
      }"
      class="
        pcard-photo
        w-full
        bg-center bg-cover
        md:w-1/2
        bg-white
        text-center text-gray-200
      "
    >
      <!-- <img
        class="h-full w-auto"
        :src="`${$api}/uploads/${specialOffer.featuredImage}`"
      /> -->
    </div>
    <div class="flex flex-col relative w-full md:w-1/2 bg-white text-black">
      <div class="text-left p-10 h-full overflow-hidden">
        <h2 class="text-xs uppercase text-highlight mb-3">
          {{ specialOffer.property ? specialOffer.property.name : "" }}
        </h2>
        <h1 class="text-3xl uppercase">{{ specialOffer.name }}</h1>

        <div class="flex">
          <div class="w-1/5 text-left">
            <p class="text-xs text-highlight mt-6">BOOK BEFORE</p>
          </div>
          <div class="w-1/2 text-left">
            <p class="text-xs text-gray-500 mt-6">
              <span class="text-black">{{
                $formatDate(specialOffer.bookBefore)
              }}</span>
            </p>
          </div>
        </div>

        <div class="flex">
          <div class="w-1/5 text-left">
            <p class="text-xs text-highlight mt-3">TRAVEL BEFORE</p>
          </div>
          <div class="w-1/2 text-left">
            <p class="text-xs text-gray-500 mt-3">
              <span class="text-black">{{
                $formatDate(specialOffer.stayBetweenEnd)
              }}</span>
            </p>
          </div>
        </div>

        <div class="relative h-full">
          <div
            v-html="specialOffer.description"
            class="mt-6 pcard-description text-xs items-center"
          ></div>
          <div class="pcard-description-overlay"></div>
        </div>
      </div>
      <div class="mt-auto w-full flex">
        <button
          @click="showQuickView = true"
          class="
            text-center
            w-1/2
            text-xs
            mt-8
            bg-white
            border-t border-l
            p-2
            px-8
            py-4
            text-gray-400
          "
        >
          QUICK VIEW
        </button>
        <router-link
          :to="`/enquiry?type=specialOffer&offer=${specialOffer._id}`"
          class="
            w-1/2
            text-center text-xs
            mt-8
            bg-gray-800
            box-border
            p-2
            px-8
            py-4
            text-white
          "
          >BOOK NOW</router-link
        >
      </div>
    </div>

    <QuickView
      :specialOffer="specialOffer"
      type="specialOffer"
      :shown="showQuickView"
      @close-modal="showQuickView = false"
    />
  </div>
</template>

<script>
import QuickView from "@/components/layout/QuickView";
export default {
  name: "SpecialOfferCard",
  props: ["specialOffer"],
  data() {
    return {
      showQuickView: false,
    };
  },
  components: {
    QuickView,
  },
};
</script>

<style scoped>
.property-card:not(:first-child) {
  margin-top: 1.5rem;
}
.pcard-description {
  max-height: 160px;
  min-height: 160px;
  overflow: hidden;
}
.pcard-description-overlay {
  max-height: 160px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 70%,
    rgba(255, 255, 255, 1) 100%
  );
}

@media (max-width: 768px) {
  .pcard-photo {
    min-height: 475px;
  }
}

@media (max-width: 634px) {
  .pcard-photo {
    min-height: 350px;
  }
}

@media (max-width: 490px) {
  .pcard-photo {
    min-height: 300px;
  }
}

@media (max-width: 410px) {
  .pcard-photo {
    min-height: 230px;
  }
}

@media (max-width: 350px) {
  .pcard-photo {
    min-height: 190px;
  }
}

@media (max-width: 300px) {
  .pcard-photo {
    min-height: 140px;
  }
}
</style>